import React, { Component,Suspense,lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LoginPage from './views/account/login';
import DefaultLayout from './views/layout/index';
//import DefaultLayout from './admin/layout';

class App extends Component {

    render() {
        return (
            <Router>
                <Suspense fallback={<></>}> 
                    <Switch>
                        <Route path="/login" exact name="Login Page" component={LoginPage} />
                        <Route path="/" name="Home" component={DefaultLayout} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
};

export default App;