/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import PostInfo from "../post/postInfo";
import Posts from "../allPosts";
import Tags from "../tags";
import Categories from "../categories";
import Users from "../users/index.js";

import ErrorBoundary from '../../components/errorBoundary';
class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <main role="main" id="main-container-fluid" className="container-fluid">
                <>
                    <Suspense fallback={<></>}>
                        <Switch>
                            <ErrorBoundary>
                                <Route path="/post/details/:id" exact render={(match) => <PostInfo enquiryid={match.match.params.id}  history={this.props.history} sidePanelShow={this.props.sidePanelShow} userData={this.props.userData} tags={this.props.tags} categories={this.props.categories} users={this.props.users} />} />
                                <Route path="/posts" exact render={(match) => <Posts history={this.props.history} sidePanelShow={this.props.sidePanelShow} userData={this.props.userData} tags={this.props.tags} categories={this.props.categories} users={this.props.users} />} />
                                <Route path="/categories" exact render={(match) => <Categories history={this.props.history} sidePanelShow={this.props.sidePanelShow} userData={this.props.userData} tags={this.props.tags} categories={this.props.categories} users={this.props.users} />} />
                                <Route path="/tags" exact render={(match) => <Tags history={this.props.history} sidePanelShow={this.props.sidePanelShow} userData={this.props.userData} tags={this.props.tags} categories={this.props.categories} users={this.props.users} />} />
                                <Route path="/users" exact render={(match) => <Users history={this.props.history} sidePanelShow={this.props.sidePanelShow} userData={this.props.userData} tags={this.props.tags} categories={this.props.categories} users={this.props.users} />} />
                            </ErrorBoundary>
                        </Switch>
                    </Suspense>
                </>
            </main>
        );
    }
}

export default MainContainer;