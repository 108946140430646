import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { InputText, InputCheckBox, ImageCropHook } from '../../components';
import _ from 'lodash'
import moment from 'moment'
import { default as _images } from "../../images";
// import 'bootstrap/dist/css/bootstrap.css';
import Swal from 'sweetalert2'

const AddUser = (props) => {
    const [errorFields, setErrorFields] = useState({})
    const [data, setData] = useState(props.data ? props.data : {
      name: '',
      email: '',
      password: '',
      cpassword: '',
      userSettingsAccess: true,
      documentID: '',
    })
    const [loader, setLoader] = useState(false)
    const [imageLoader, setImageLoader] = useState(false)
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })


    const handleOnChange = e => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value,
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    const onSave = async e => {
        e.preventDefault()
        let isValid = true;
        let errors = { ...errorFields }

        if (data.name.trim() === '') {
            isValid = false;
            errors.name = 'input_error'
        }

        if (data.email.trim() === '') {
          isValid = false;
          errors.email = 'input_error'
        }

        if (props.title === 'Add User' && data.password.trim() === '') {
          isValid = false;
          errors.password = 'input_error'
        }

        if (props.title === 'Add User' && data.cpassword.trim() === '') {
          isValid = false;
          errors.cpassword = 'input_error'
        }

        if (props.title === 'Add User' && data.password.trim() !== data.cpassword.trim()) {
          isValid = false;
          errors.password = 'input_error'
          errors.cpassword = 'input_error'

          Swal.fire('Passwords not matched', '', 'error')
        }

        setErrorFields(errors);

        if (!isValid) {
          return
			  } else {
          setLoader(true)
          if (props.title === 'Edit User') {
              const objData = {
                ...data,
                name: data.name.trim() ? data.name.trim() : '',
                modifiedDate: window.firebase.firestore.Timestamp.now()
              }

              if (props.data.email !== objData.email) {
                console.log('email update')
                const updateUserEmail = window.firebase.functions().httpsCallable('updateUserEmail');
                const resp = await updateUserEmail({
                  "email": objData.email,
                  "uid": objData.documentID
                });
                console.log('update user resp', resp)
                if (resp.data.success === true) {
                }
                else {
                    Swal.fire(resp.data.message, '', 'error')
                    setLoader(false)
                    return;
                }
              }
              console.log('props.data.documentID', props.data.documentID)
              console.log('objData.documentID', objData.documentID)
              const ref = window.firebase.firestore().collection('users').doc(objData.documentID)
              ref.set(objData, {merge: true})


              console.log('update objData', objData)
              props.handleClose(objData)
              setLoader(false)
          } else {
              const objData = {
                ...data,
                documentID: window.firebase.firestore().collection('users').doc().id,
                name: data.name.trim() ? data.name.trim() : '',
                addedDate: window.firebase.firestore.Timestamp.now(),
                isEnabled: true,
              }

              // create function call
              const createUser = window.firebase.functions().httpsCallable('createUser');
              const resp = await createUser({
                "email": objData.email,
                "password": objData.password,
                "name": objData.name,
              })  
              console.log('add user resp', resp)
              if (resp.data.success === true) {
                let documentID = resp.data.data;
                objData.documentID = resp.data.data;
                // updateUser(user)
                delete objData.password
                delete objData.cpassword
                
                const ref = window.firebase.firestore().collection('users').doc(documentID)
                ref.set(objData, {merge: true})
              }
              else {
                  Swal.fire(resp.data.message, '', 'error')
                  setLoader(false)
                  return
              }

              // create gunction resp


              
              console.log('add objData', objData)
              props.handleClose(objData)
              setLoader(false)
          }
        }
    }

    const handleCheckChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.checked
      })
    }

    const onSelectFile = (e, type, id, title) => {
      if (e.target.files && e.target.files.length > 0) {
          if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
              Swal.fire('File format not supported. Please select image file.', '', 'info')
              return;
          }
          var img = e.target.files[0].size;
          var imgsize = img / 1024 / 1024;
          if (imgsize > 10) {
              Swal.fire('Maximum file size exceeded.', '', 'info')
              return;
          }

          let reader = new FileReader();
          let file = e.target.files[0];

          reader.onloadend = () => {

              setCropShow({
                  show: true,
                  clsActive: 'overlay-modal active',
                  src: reader.result,
                  id: id,
                  type: type,
                  ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                  title: title
              })
          }
          reader.readAsDataURL(file)
      }
    }

    const handleCropClose = (id) => {
      if (id && document.getElementById(id))
          document.getElementById(id).value = "";
      setCropShow({
          show: false,
          clsActive: '',
          src: null,
          id: '',
          type: '',
          ext: '',
          title: ''
      })
    }

    const handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        setData({
            ...data,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);


        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
      let uid = moment()._d.getTime().toString() + '.' + ext;
      setImageLoader(true)
      var storageRef = window.firebase.storage().ref(`users/${uid}`).putString(imageBase64, 'data_url');
      storageRef.on('state_changed', (snapshot) => {
          var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

      },
          (error) => {
              //console.log(error);
          },
          () => {
              window.firebase.storage().ref(`users`).child(uid).getDownloadURL()
                  .then(dataURL => {
                      setImageLoader(false)
                      setData({
                          ...data,
                          [id]: dataURL
                      });
                  })
          })
     };

    return (<>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-add-user">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > {props.title}</h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">
                    <div className="form-style w-100">
                    <div className="form-row settings-profile pt-2" style={{flexWrap: 'inherit'}}>
                      <div className="settings-profileimage">
                          {
                              (imageLoader)
                                  ?
                                  <div className="img-loader pl-2">
                                      <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                          <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                      </div>
                                  </div>
                                  :
                                  <></>
                          }
                          {
                              (!_.isEmpty(data.profileImage))
                                  ?
                                  <a data-fancybox={`profileImage`} href={data.profileImage} onClick={(e) => { e.preventDefault(); }}>
                                      <img src={data.profileImage} alt="" className="rounded-circle img-object-fit" />
                                  </a>
                                  :
                                  <img src={_images.nouser} alt="" className="rounded-circle img-object-fit" />
                          }

                      </div>
                      <div className="settings-profile-info">
                          <div className="settings-profile-upload">
                              <label htmlFor="profileImage" className={`btn-common float-left mr-2 font-600`}>Choose Picture
                                  <input className="fileInput"
                                      type="file"
                                      name="profileImage"
                                      id="profileImage"
                                      accept="image/*"
                                      style={{ display: 'none' }}
                                      onChange={(e) => onSelectFile(e, 'users', 'profileImage', 'Profile Image')} />
                              </label>
                              {/* <div className={`settings-delete-button ${_.isEmpty(data.profileImage) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                  onClick={(e) => {
                                      e.preventDefault();
                                      setData({
                                          ...data,
                                          ['profileImage']: ''
                                      });
                                  }}> <i className="ico icon-delete"></i></a>
                              </div> */}
                          </div>
                          <div className=" settings-limit">Max size 10 MB. Formats: JPG, GIF, PNG.</div>
                      </div>

                      </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["name"]}`}
                                    name="name"
                                    onChange={handleOnChange}
                                    value={data.name}
                                    placeholder='name'
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label>Email</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["email"]}`}
                                    name="email"
                                    onChange={handleOnChange}
                                    value={data.email ? data.email : ''}
                                    placeholder='email'
                                />
                            </div>
                          </div>
                          {props.title === 'Add User' && (
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                  <label>Password</label>
                                  <input
                                      className={`form-control ${errorFields["password"]}`}
                                      name="password"
                                      onChange={handleOnChange}
                                      value={data.password ? data.password : ''}
                                      placeholder='confirm password'
                                      type='password'
                                  />
                              </div>
                              <div className="form-group col-md-6">
                                  <label>Confirm Password</label>
                                  <InputText
                                      className={`form-control ${errorFields["cpassword"]}`}
                                      name="cpassword"
                                      onChange={handleOnChange}
                                      value={data.cpassword ? data.cpassword : ''}
                                      placeholder='confirm password'
                                      type='password'
                                  />
                              </div>
                            </div>
                          )}
                          <div className="form-row">
                            <div className="form-group col-md-6">
                                <label className='checkbox-access-user'> Access To User Settings </label>
                                <span className="switch switch-sm mr-2 w-100 d-inline-block mt-2">
                                    <InputCheckBox
                                        id="enable-mfa"
                                        className="switch"
                                        name={'userSettingsAccess'}
                                        checked={Boolean(data.userSettingsAccess)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="enable-mfa">Enable</label>
                                </span>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" style={{width: '80px'}} className={`btn btn-add-new-fix btn-primary btn-primary-fix float-right`} onClick={onSave}>
              {loader && (<span style={{width: '1rem', height: '1rem', marginRight: '4px'}} className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)}
              {props.title === 'Edit User' ? 'Update' : 'Add'}
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                Cancel
            </button>

        </Modal.Footer>
    </Modal>
    <ImageCropHook
        cropShow={cropShow}
        handleClose={handleCropClose}
        handleSelect={handleCropSelect}
    ></ImageCropHook>
    </>)
}

export default AddUser;