import React, { useEffect, useState } from 'react'
import { default as _images } from "../../images";
import { InputText, ReactMultiSelect, ReactSelect, SummerEditor, SideNav, PopUpModal, PreviewPost } from '../../components'
import { visibilities } from './viewModel'
import _ from 'lodash';
import Swal from 'sweetalert2'
import moment from 'moment'
import { createAllSpaceKeywords } from '../../common/index'

const PostInfo = ({ enquiryid, categories, users, history, userData }) => {
  const [fields, setFields] = useState({
    title: '',
    category: '',
    description: '',
    tags: [],
    htmlContent: '',
    status: 'Draft',
    visibility: 'Public',
    imageURL: '',
    isSaved: false,
  })
  const [imageLoader, setImageLoader] = useState(false)
  const [editSettings, setEditSettings] = useState(false)
  const [errorFields, setErrorFields] = useState({})
  const [loader, setLoader] = useState(true)
  const [isNew, setIsNew] = useState(true)
  const [email, setEmail] = useState('<p></p>'
		// props.template ? props.template.bodyHTML : '<p></p>',
	);
  const [showPreviewTemplate, setShowPreviewTemplate] = useState({
		show: false,
		title: '',
    data: null,
    image: null,
	})

  const [saveAsDraftLoader, setSaveAsDraftLoader] = useState(false)
  const [publishLoader, setPublishLoader] = useState(false)
  const [saveLoader, setSaveLoader] = useState(false)
  const [title, setTitle] = useState('Add New Post')

  useEffect(() => {
    const getPostData = async () => {
      if (_.isEmpty(users)) {
        return
      }
      setLoader(true)
      if (!enquiryid) {
        setLoader(false)
        return
      }
      const snap = await window.firebase.firestore().collection('posts').doc(enquiryid).get()
      if (snap.exists) {
        const postData = snap.data()
        console.log('postData', postData)
        setEmail(postData.htmlContent)
        setFields({
          title: postData.title ? postData.title : '',
          category: postData.category ? postData.category : '',
          description: postData.description ? postData.description : '',
          // tags: !_.isEmpty(postData.tags) ? postData.tags : '',
          author: users.length ? users.filter(a => a.documentID === postData.addedBy)[0].name : '',
          status: postData.status ? postData.status : '',
          visibility: postData.visibility ? postData.visibility : '',
          imageURL: postData.imageURL ? postData.imageURL : '',
          isSaved: false,
          addedDate: !_.isEmpty(postData.addedDate) ? postData.addedDate : '',
          publishedDate: !_.isEmpty(postData.publishedDate) ? postData.publishedDate : '',
        })
        setIsNew(false)
        setTitle('Edit Post')
      }
      setLoader(false)
    }
    getPostData()
  }, [users])
  

  const handleChange = obj => {
		setEmail(obj);
	};

  const handleOnChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    var array1 = value.split(' ');
    var str = value;

    if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
            newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
        }
        setFields({
            ...fields,
            [name]: newarray1.join(' ')
        });
    } else {
        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setFields({
            ...fields,
            [name]: str
        });
    }

    setErrorFields({
        ...errorFields,
        [name]: ''
    });
  }

  const handleDescChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value
  });
    setErrorFields({
        ...errorFields,
        [name]: ''
    });
  }

  // const handleReactMultiSelectChange = (selectedOptions) => {
  //   const value = [];

  //   !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
  //       value.push(data.value)
  //   })

  //   setFields({
  //       ...fields,
  //       ['tags']: value
  //   });

  //   setErrorFields({
  //       ...errorFields,
  //       ['tags']: ''
  //   });
  // }

  const handleReactSelectChange = (e, data) => {
    console.log('data', data)
    console.log('e', e)
    setFields({
      ...fields,
      [data.name]: e && e.value ? e.value : ''
    });

    setErrorFields({
        ...errorFields,
        [data.name]: ''
    });
  }

  const onSave = async (type) => {
    console.log('fields', fields)

    let _errorFields = {}
    if (fields.title.trim() === '') {
      _errorFields.title = 'input_error'
    }
    if (fields.description.trim() === '') {
      _errorFields.description = 'input_error'
    }

    if (fields.category === '') {
      _errorFields.category = 'input_error'
    }

    if (fields.visibility === '') {
      _errorFields.visibility = 'input_error'
      setEditSettings(true)
    }

    if (fields.imageURL === '') {
      _errorFields.imageURL = 'error-validate'
    }

    setErrorFields({
      ..._errorFields
    })

    console.log('_errorFields', _errorFields)

    if (!_.isEmpty(_errorFields)) {
      return
    }
    
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: type === 'draft' ? 'Do you want to save this post as draft?' : 'Do you want to publish this post?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })

    if (!result.value) {
      return
    }

    if (type === 'publish') {
      setPublishLoader(true)
    } else {
      setSaveAsDraftLoader(true)
    }


    const ref = window.firebase.firestore().collection('posts').doc(enquiryid)
    const data = {...fields}
    data.htmlContent = email
    data.title = fields.title.trim()
    data.description = fields.description.trim()
    data.documentID = enquiryid
    data.modifiedDate = window.firebase.firestore.Timestamp.now();
    data.modifiedBy = localStorage.uid
    data.isDeleted = false
    data.keywords = createAllSpaceKeywords(data.title)
    if (isNew) {
      data.addedDate = window.firebase.firestore.Timestamp.now();
      data.addedBy = localStorage.uid
    }
    if (type === 'publish') {
      data.publishedDate = window.firebase.firestore.Timestamp.now();
      data.publishedBy = localStorage.uid
      data.status = 'Published'
    }
    delete data.isSaved

    await ref.set(data, {merge: true})

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'info',
      title: type === 'publish' ? 'Post published successfully.' : 'Post save as draft successfully.'
    })

    history.push('/posts')
    console.log('post object', data)
    
    if (type === 'publish') {
      setPublishLoader(false)
    } else {
      setSaveAsDraftLoader(false)
    }
  }

  const onSavePublished = async () => {
    console.log('fields', fields)

    let _errorFields = {}
    if (fields.title === '') {
      _errorFields.title = 'input_error'
    }
    if (fields.description.trim() === '') {
      _errorFields.description = 'input_error'
    }

    if (fields.category === '') {
      _errorFields.category = 'input_error'
    }

    if (fields.visibility === '') {
      _errorFields.visibility = 'input_error'
      setEditSettings(true)
    }

    if (fields.imageURL === '') {
      _errorFields.imageURL = 'error-validate'
    }

    setErrorFields({
      ..._errorFields
    })

    console.log('_errorFields', _errorFields)

    if (!_.isEmpty(_errorFields)) {
      return
    }

    setSaveLoader(true)


    const ref = window.firebase.firestore().collection('posts').doc(enquiryid)
    const data = {...fields}
    data.htmlContent = email
    data.documentID = enquiryid
    data.modifiedDate = window.firebase.firestore.Timestamp.now();
    data.modifiedBy = localStorage.uid
    data.keywords = createAllSpaceKeywords(data.title)
    data.isDeleted = false
    data.title = fields.title.trim()
    data.description = fields.description.trim()

    delete data.isSaved

    await ref.set(data, {merge: true})

    // for (let index = 0; index < 11; index++) {
    //   const _data = {...data} 
    //   _data.documentID = window.firebase.firestore().collection('posts').doc().id
    //   data.modifiedDate = window.firebase.firestore.Timestamp.now();
    //   data.addedDate = window.firebase.firestore.Timestamp.now();
    //   window.firebase.firestore().collection('posts').doc(_data.documentID).set(_data)
    //   console.log(index, _data.documentID, _data)      
    // }

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'info',
      title: 'Post updated successfully.'
    })


    history.push('/posts')
    console.log('post object', data)
    setSaveLoader(false)
  }

  const handleImageChange = e => {
    e.preventDefault();
    if (imageLoader) {
      return
    }
    setImageLoader(true)

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      saveImageToStorage(reader.result)
    }

    reader.readAsDataURL(file)
  }

  const handlePreview = () => {
    setShowPreviewTemplate({
      show: true,
      title: 'Preview Post',
      data: email,
      image: fields.imageURL
    })
  }

  const saveImageToStorage = (imageBase64, id, ext) => {
    let uid = moment()._d.getTime().toString();
    var storageRef = window.firebase.storage().ref(`posts/${enquiryid}/${uid}`).putString(imageBase64, 'data_url');
    storageRef.on('state_changed', (snapshot) => {
        //console.log(snapshot);
    },
        (error) => {
            console.error(error);
        },
        () => {
            window.firebase.storage().ref(`posts/${enquiryid}`).child(uid).getDownloadURL()
                .then(dataURL => {
                  console.log('donwloadURL', dataURL)
                    // this.setState({ signatureURL: dataURL }, () => { this.errorChange(id) });  
                    setFields({
                      ...fields,
                      imageURL: dataURL,
                    });                      
                    setImageLoader(false)
                    setErrorFields({
                      ...errorFields,
                      imageURL: ''
                    })
                }).catch(e => {console.log(e); setImageLoader(false)})
        })
  };

  const handleRemoveImage = () => {
    setFields({
      ...fields,
      imageURL: '',
    });
  }

  return (
    <div id="layoutSidenav">
			<SideNav userData={userData} history={history} />
			<div id="layoutSidenav_content">
        {loader ? (
        <div className='blog-loader d-flex align-items-center justify-content-center' style={{height: '50vh'}}>
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        ) : (
				<main>
					<div className="container-fluid px-4">
						<h1 className="mt-4 mb-4">
							{title}

							<div className="float-right">
								{fields.status === 'Draft' ? (
                  <>
                  <button
									type="button"
									className="btn btn-primary btn-sm btn-primary-fix float-right ms-2 email-send-btn publish-btn"
                  onClick={e => onSave('publish')}
								>
									{/* <i className="fas fa-long-arrow-alt-right me-2 publish-icon"></i> */}
                  {publishLoader && (<span style={{width: '1rem', height: '1rem', marginRight: '4px'}} className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)}
									Publish
								</button>

								<button
									type="button"
									className="btn btn-secondary float-right ms-2"
                  onClick={e => onSave('draft')}
								>
                  {saveAsDraftLoader && (<span style={{width: '1rem', height: '1rem', marginRight: '4px'}} className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)}
									Save as Draft
								</button>
                </>
                ) : (
                <button
									type="button"
									className="btn btn-primary btn-sm btn-primary-fix float-right ms-2 email-send-btn save-blog-btn"
                  onClick={e => onSavePublished()}
								>
                  {saveLoader && (<span style={{width: '1rem', height: '1rem', marginRight: '4px'}} className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)}
									Save
								</button>
                )}
								<button type="button" className="btn btn-default float-right ms-2" onClick={e => handlePreview() }>
									Preview
								</button>
							</div>
						</h1>

						<div className="inner-detail-grid flex-column mt-4">
							<div className="mid-column">
								<div className="mid-column-wrap h-100">
									<div className="form-style w-100">
										<div className="form-row">
											<div className="form-group col-md-12">
												<label>Blog Title</label>
                        <InputText
                            autoComplete="off"
                            placeholder={'title'}
                            className={`form-control ${errorFields["title"]}`}
                            name="title"
                            onChange={handleOnChange}
                            value={fields.title}
                            id="blog-title"
                        />
											</div>

                      <div className="form-group col-md-12">
												<label htmlFor="">Select Category</label>
												<ReactSelect
                          options={_.map(categories, function (e) { return { label: e.name, value: e.value, data: e } })}
                          name={"category"}
                          placeholder={'select category'}
                          onChange={(e, data) => {
                              // setSelectedGroup(e ? e.data : null);
                              handleReactSelectChange(e, data)
                          }}
                          value={fields.category}
                          classNamePrefix={`${errorFields["category"]} cursor-pointer basic-select`}
                          removeClearable={false}
                          id="blog-category"
                        >
                        </ReactSelect>
											</div>

                      <div className="form-group col-md-12">
												<label>Blog Description</label>
                        <InputText
                            autoComplete="off"
                            placeholder={'blog description'}
                            className={`form-control ${errorFields["description"]}`}
                            name="description"
                            onChange={handleDescChange}
                            value={fields.description}
                            id="blog-description"
                        />
											</div>

											{/* <div className="form-group col-md-12">
												<label htmlFor="">Select Tags</label>
												<ReactMultiSelect
                            options={tags}
                            name={"tags"}
                            placeholder={'select tags'}
                            onChange={handleReactMultiSelectChange}
                            value={fields.tags}
                            classNamePrefix={`${errorFields["tags"]} basic-select`}
                            isMulti={true}
                            id="tags"
                        >
                        </ReactMultiSelect>
											</div> */}

											<div className='form-group col-md-12 react-summer-editor mt-3'>
                        <SummerEditor
                          enquiryid={enquiryid}
                          value={email}
                          height={window.innerHeight - 250 + 'px'}
                          handleChange={handleChange}
                          emailType={'compose'}
                          templateMentionsEnabled={true}
                        />
                      </div>
										</div>
									</div>
								</div>
							</div>

							<aside className="small-column ms-3">
								<div className="inner-detail-right-panel">
									<div className="sidebar-title">
										Publish
										<div className="float-right">
											<a href="#" className="mini-button ms-2 edit-btn-fix" onClick={e => {
                        e.preventDefault();
                        setEditSettings(!editSettings)
                      }}>
												<i className="fas fa-pen "></i></a>
										</div>
									</div>

									<div className="right-panel-loop">
										<div className="sidebar-head">Status</div>
                    <div className="sidebar-subpanel">
                        <div className={`badge badge-pill badge-${fields.status === 'Draft' ? 'grey' : 'green'}`}>{fields.status}</div>
                      </div>
									</div>

									<div className="right-panel-loop">
										<div className="sidebar-head">
											<i className="fa fa-eye" aria-hidden="true"></i> Visibility
										</div>
                    {editSettings ? (
                        <>
                          <ReactSelect
                            options={_.map(visibilities, function (e) { return { label: e.name, value: e.value, data: e } })}
                            name={"visibility"}
                            placeholder={'select visibility'}
                            onChange={(e, data) => {
                                // setSelectedGroup(e ? e.data : null);
                                handleReactSelectChange(e, data)
                            }}
                            value={fields.visibility}
                            classNamePrefix={`${errorFields["visibility"]} cursor-pointer basic-select`}
                            removeClearable={false}
                            id="blog-visibility"
                          />
                        </>
                      ) : (
										    <div className="sidebar-subpanel-tag">{fields.visibility ? fields.visibility : 'Public'}</div>
                      )}
									</div>

                  {!isNew ? (
                    <>
                    <div className="right-panel-loop">
                      <div className="sidebar-head">Created On</div>

                      <span className="created-title"> {fields.author ? fields.author : ''} </span>
                      <span className="created-time">{moment(fields.addedDate.toDate()).format('DD/MM/YYYY hh:mm A')}</span>
                    </div>

                    {fields.status === 'Published' && (
                      <div className="right-panel-loop">
                        <div className="sidebar-head">Published On</div>

                        <span className="created-title"> {fields.author ? fields.author : ''} </span>
                        <span className="created-time">{moment(fields.publishedDate.toDate()).format('DD/MM/YYYY hh:mm A')}</span>
                      </div>
                    )}
                    </>
                  ) : (<></>)}

									<div className="sidebar-title mt-4">Featured Image</div>

									<div className={`sidebar-uploadbtn-wrap ${errorFields["imageURL"]}`}>
                    {fields.imageURL && (
                      <div className="close-ico-top">
                        <a href="#" onClick={handleRemoveImage}
                          ><i className="fa fa-times-circle" aria-hidden="true"></i>
                        </a>
                      </div>
                    )}
										<img src={fields.imageURL ? fields.imageURL : 'https://i.stack.imgur.com/y9DpT.jpg'} width="100%" />
										<div className="sidebar-upload-btn">
											<div className="img-recommend">
												Recommended (1024 x 768px)
											</div>
                      {/* {imageLoader && (
                        <>Loading</>
                      )}
											<input type='file' onChange={(e) => handleImageChange(e)}/> */}
                      <a href="#" className="btn sidebar-btn float-right">
                        <label style={{ margin:'0',cursor:'pointer', color:'#fff',}}> 
                        {imageLoader ? (<span style={{width: '1rem', height: '1rem', marginRight: '4px'}} className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>) : (
                          <i className="fa fa-upload" aria-hidden="true"></i> 
                        )} Upload 
                          <input type="file" id="" style={{display:'none'}} onChange={(e) => handleImageChange(e)} />
                        </label>
                      </a>
										</div>
									</div>
								</div>
							</aside>
						</div>
					</div>
				</main>
        )}
				<footer className="py-4 mt-auto">
					<div className="container-fluid px-4">
						<div
							className="d-flex align-items-center justify-content-between small"
						>
							<div className="text-muted">
								&copy; 2021 Fusion Software Development
							</div>
						</div>
					</div>
				</footer>
			</div>
      <PopUpModal show={showPreviewTemplate.show}>
        <PreviewPost
          show={showPreviewTemplate.show}
          handleClose={() =>
            setShowPreviewTemplate({
              ...showPreviewTemplate,
              title: '',
              show: false,
              data: null,
              image: null,
            })}
          title={showPreviewTemplate.title}
          bodyHTML={showPreviewTemplate.data}
          image={showPreviewTemplate.image}
          // bodyHTML={!_.isEmpty(data.template) ? data.template.bodyHTML : null}
        />
      </PopUpModal>
		</div>
  )
}

export default PostInfo
