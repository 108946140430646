/** LIBRARIES */
import React, { PureComponent } from "react";

class InputCheckBox extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { id, name, onChange, className, checked } = this.props;
        //console.log('RENDER INPUT RADIO', name, value)
        return (
            <input
                id={id ? id : name}
                name={name}
                type="checkbox"
                className={className}
                onChange={onChange}
                checked={checked ? checked : false}
            />

        );
    }
}

export default InputCheckBox;