import React, { useEffect, useState, useCallback } from 'react';
import { tagsVM, categoriesVM } from '../post/viewModel'
import _ from 'lodash'
import Swal from 'sweetalert2'

export const MainContext = React.createContext();

export const LayoutConsumer = MainContext.Consumer;

const LayoutProvider = ({ history, children }) => {
	const [categories, setCategories] = useState([])
	const [tags, setTags] = useState([])
	const [user, setUser] = useState([])
	const [users, setUsers] = useState([])

	useEffect(() => {
		if (!localStorage.uid) {
			history.push('/login')
		} else {
			if (window.location.pathname === '/') {
				history.push(`/posts`)
			}
		}
	}, [])

	useEffect(() => {
		const tagsSnapshot = window.firebase.firestore().collection(`tags`)
		.onSnapshot(querySnapshot => {
				let _tags = [];
				querySnapshot.forEach((rec) => {
						_tags.push({ ...rec.data(), 'id': rec.id, 'addedDate': rec.addedDate ? rec.addedDate : window.firebase.firestore.Timestamp.now() })
				})
				setTags(_.orderBy(_tags,['addedDate'],['desc']))
		})
		return () => {
				tagsSnapshot && tagsSnapshot();
		}
	}, [])

	useEffect(() => {
		const categoriesSnapshot = window.firebase.firestore().collection(`categories`)
		.onSnapshot(querySnapshot => {
				let _categories = [];
				querySnapshot.forEach((rec) => {
						_categories.push({ ...rec.data(), 'id': rec.id, 'addedDate': rec.addedDate ? rec.addedDate : window.firebase.firestore.Timestamp.now() })
				})
				setCategories(_.orderBy(_categories, ['name'],['asc']))
		})
		return () => {
				categoriesSnapshot && categoriesSnapshot();
		}
	}, [])

	useEffect(() => {
		const allUsers = window.firebase.firestore().collection(`users`)
		.onSnapshot(querySnapshot => {
				let _users = [];
				querySnapshot.forEach((rec) => {
						_users.push({ ...rec.data(), 'addedDate': rec.addedDate ? rec.addedDate : window.firebase.firestore.Timestamp.now() })
				})
				setUsers(_.orderBy(_users, ['name'],['asc']))
		})
		return () => {
				allUsers && allUsers();
		}
	}, [])

	useEffect(() => {
		if (!localStorage.uid) {
			return
		}		
		const userSnapshot = window.firebase.firestore().collection(`users`).doc(localStorage.uid)
		.onSnapshot(querySnapshot => {
				const _data = querySnapshot.data()
				if (!_.isEmpty(_data) && _data.isEnabled === false) {
					console.log('this user is disabled')
          Swal.fire('Your account is disabled by the admin', '', 'error')
					window.firebase.auth().signOut()
						.then(() => {
								localStorage.clear();
								history.push('/login')
						})
						.catch(error => {
								console.error(error);
						});
				} else {
					setUser(querySnapshot.data())
				}
		})
		return () => {
				userSnapshot && userSnapshot();
		}
	}, [])

  return (
    	<MainContext.Provider
				value={{
					categories,
          tags,
					users,
					userData: user,
				}}
			>
				{children}
			</MainContext.Provider>
  )
}

export default LayoutProvider
