import React from 'react';
import { Modal } from 'react-bootstrap';

const PreviewPost = props => (
	<Modal
		show={props.show}
		onHide={props.handleClose}
		backdrop='static'
		backdropClassName={props.show ? 'overlay-modal active' : ''}
		dialogClassName='modal-dialog-centered modal-preview-custom modal-custom preview-modal'
	>
		<Modal.Header closeButton>
			<Modal.Title>
				<h5 className='modal-title'>
					{' '}
				{props.title ? props.title : 'Preview'}
				</h5>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className='container-fluid'>
        <img src={props.image} style={{ width: '100%'}}/>
				{props.bodyHTML.length > 80 ? <div
						dangerouslySetInnerHTML={{
							__html: props.bodyHTML,
						}}
					></div> : (
						<h3 className='pt-5 pb-5 text-center'>No HTML Content Added yet</h3>
				)}
			</div>
		</Modal.Body>
		<Modal.Footer className='modal-footer'>
			<button
				type='button'
				className='btn btn-default float-right'
				onClick={() => {
					props.handleClose();
				}}
			>
				Close
			</button>
		</Modal.Footer>
	</Modal>
);

export default PreviewPost;
