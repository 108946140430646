import React, { useContext, useEffect, useState } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import moment from 'moment';
import $ from 'jquery';
// import { MainContext } from '../../layout/provider';
import Swal from 'sweetalert2';
// import toast from 'toasted-notes';
// import { mentions } from '../../settings/workflow/templates/viewModel';
window.jQuery = $;

const mentions = []

const SummerEditor = ({ enquiryid, handleChange, value, height, emailType, templateMentionsEnabled }) => {
	const [initialized, setInitialized] = useState(false);
	const [imagesSize, setImagesSize] = useState(0);

	// const { dealersettings } = useContext(MainContext);
	useEffect(() => {
		if (emailType === 'compose') {
			if (!initialized) {
				// console.log('emailType', emailType)
				var node = document.createElement('div');
				node.innerHTML = `${value}`;
				ReactSummernote.insertNode(node);
				setInitialized(true);
			}
		}
	}, []);

	const handleImageUpload = async fileList => {
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer)
				toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		try {
			// file sizes validation
			let fileListSize = 0;
			for (let i = 0; i < fileList.length; i++) {
				// console.log(fileList[i].size)
				fileListSize = fileListSize + fileList[i].size;
			}
			fileListSize = fileListSize / 1024 / 1024;
			let totalSize = imagesSize + fileListSize;

			if (totalSize > 25) {
				Swal.fire('Maximum files size exceeded.', '', 'info');
				return;
			} else {
				setImagesSize(totalSize);
			}
			// toast.notify('Image is being uploaded...');
			Toast.fire({
				icon: 'info',
				title: 'Image is being uploaded...'
			})
			for (let i = 0; i < fileList.length; i++) {
				// console.log(fileList[i])
				const imageURL = await saveFileToStorage(fileList[i]);
				console.log('imageURL', imageURL);
				ReactSummernote.insertImage(imageURL);
			}
			// toast.notify('Image has been added.');
			Toast.fire({
				icon: 'success',
				title: 'Image has been added.'
			})
		} catch (error) {
			console.error(error)
			// toast.notify('There was an error in adding images...');
			Toast.fire({
				icon: 'info',
				title: 'There was an error in adding image.'
			})
		}
	};

	const saveFileToStorage = async file => {
		let clientID = '';
		let uid = moment()._d.getTime().toString();
		try {
			const res = await window.firebase
				.storage()
				.ref(
					`${'posts'}/${enquiryid}/${file.name}`,
				)
				.put(file);
			if (res.state === 'success') {
				const dataURL = window.firebase
					.storage()
					.ref(`${'posts'}/${enquiryid}`)
					.child(file.name)
					.getDownloadURL();
				return dataURL;
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="react-summer-editor">
			<ReactSummernote
				children={
					<div
						id='summer-editor'
						dangerouslySetInnerHTML={{ __html: `<div></div>` }}
					></div>
				}
				options={{
					height,
					hint: templateMentionsEnabled ? {
						mentions: mentions.map(mention => mention.name),
						match: /\B@(\w*)$/,
						search: function (keyword, callback) {
							callback($.grep(this.mentions, function (item) {
								return item.indexOf(keyword) == 0;
							}));
						},
						content: function (item) {
								let valueToBeInserted = mentions.filter(mention => item === mention.name)[0].value
								return valueToBeInserted;
						}
					} : {},
					dialogsInBody: true,
					toolbar: [
						['style', ['style']],
						['font', ['bold', 'italic', 'underline', 'clear']],
						['fontname', ['fontname']],
						['para', ['ul', 'ol', 'paragraph']],
						['color', ['color']],
						// ['table', ['table']],
						['insert', ['link', 'picture']],
						['view', ['codeview', 'help']]
					],
					disableResizeEditor: true,
				}}
				onChange={handleChange}
				onImageUpload={handleImageUpload}
			/>
		</div>
	);
};

export default SummerEditor;
