import React, { useEffect, useState } from 'react'
import { default as _images } from "../../images";
import moment from 'moment'

const Login = ({ history, location, match }) => {
	const [state, setState] = useState({
		email: '',
		password: '',
	})
	const [errors, setErrors] = useState({})
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		if (localStorage.uid) {
			history.push(`/`)
		} 
	}, [])

  useEffect(() => {
		document.getElementsByTagName('body')[0].classList.add('login-page-landing');
		return () => {
      document.getElementsByTagName('body')[0].classList.remove('login-page-landing');
		};
	}, []);

	const handleEmailChange = (e) => {
		setState({
			...state,
			email: e.target.value
		})
	}

	const handlePasswordChange = (e) => {
		setState({
			...state,
			password: e.target.value
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		let errors = {};
		if (handleValidation()) {
				setLoader(true)

				window.firebase.auth().signInWithEmailAndPassword(state["email"], state["password"])
						.then(() => {
								const currentUser = window.firebase.auth().currentUser;
								if (currentUser) {
										const uid = currentUser.uid;
										const userData = { lastLoginTime: moment()._d };
										// getBrowserkey()
										// deleteDuplicateTokens(uid);
										localStorage.setItem('uid', uid);
										localStorage.setItem('userName', (currentUser.displayName ? currentUser.displayName : currentUser.email));
										window.firebase.firestore().doc(`/users/${uid}`).set(userData, { merge: true });
										window.firebase.auth().currentUser.getIdTokenResult().then((data) => {
												let search = window.location.search;
												let params = new URLSearchParams(search);
												//let continueURL = params.get('continue');
												// if (continueURL) {
												//     props.history.push(continueURL)
												// } else {
												history.push('/')
												// }
												setLoader(false)
										});
								}
								//this.setState({ loading: false })
						})
						.catch(error => {
								//console.log(error);
								errors["message"] = error.message
								setErrors(errors)
								setLoader(false)
						});
		}
	}

	const handleValidation = () => {
		let fields = Object.assign({}, state);
		let errors = {};
		let formIsValid = true;

		//Name
		if (!fields["email"]) {
				formIsValid = false;
				errors["email"] = "error";
		}

		//Name
		if (!fields["password"]) {
				formIsValid = false;
				errors["password"] = "error";
		}

		if (typeof fields["email"] !== "undefined") {
				let lastAtPos = fields["email"].lastIndexOf('@');
				let lastDotPos = fields["email"].lastIndexOf('.');

				if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
						formIsValid = false;
						errors["message"] = "Email is not valid";
				}
		}
		setErrors(errors)
		return formIsValid;
	}

  return (
    <div className="main-content h-100">

			{/* <!-- Header --> */}
			<nav className="navbar navbar-expand-md navbar-dark navbar-top">
				<div className="container-fluid p-0">
					<div className="float-left">
						<a className="navbar-logo" href="#"
							><img src={_images.logo} height="40" alt=""
						/></a>
					</div>
				</div>
			</nav>

			{/* <!-- middle --> */}

			<div className="container mrg-top">
				<div className="row justify-content-center">
					<div className="col-lg-4 col-md-6">
						<div className="card bg-secondary shadow border-0">
							<div className="card-body px-lg-5 py-lg-5">
								<div className="text-center text-muted mb-5 login-head">
									<h2>Login</h2>
									<h4>Please log in to continue.</h4>
								</div>
								<form role="form">
									<div className="form-group mb-3">
										<div className={`input-group input-group-alternative ${errors.email}`}>
											<div className="input-group-prepend">
												<span className="input-group-text"
													><i className="ico icon-login-user"></i>
												</span>
											</div>
											<input
												className="form-control"
												placeholder="Email"
												type="email"
												onChange={handleEmailChange}
												value={state.email}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className={`input-group input-group-alternative ${errors.password}`}>
											<div className="input-group-prepend">
												<span className="input-group-text"
													><i className="ico icon-login-password"></i>
												</span>
											</div>
											<input
												className="form-control"
												placeholder="Password"
												type="password"
												onChange={handlePasswordChange}
												value={state.password}
											/>
										</div>
									</div>

									{/* <div className="remember mt-2">
										<div className="checkbox icheck-success">
											<input type="checkbox" checked="" id="success" />
											<label htmlFor="success">Remember me</label>
										</div>
									</div> */}

									{/* <div className="error-wrap">Incorrect email or password</div> */}

									<div className="text-center mt-2">
										<button type="button" className="btn btn-primary mt-4" onClick={e => {
                      e.preventDefault(); handleSubmit(e);
                    }}>
											{loader && (<span style={{width: '20px', height: '20px', marginRight: '6px'}} className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)}
											Login
										</button>
									</div>
									{/* <div className="row mt-4">
										<div className="col-12">
											<div className="forgot-password">
												<a
													href="#"
													data-toggle="modal"
													data-target="#reset-password"
													>Forgot password?</a
												>
											</div>
										</div>
									</div> */}
								</form>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-12 copyright">
								&copy; 2021 Fusion Software Development
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  )
}

export default Login
