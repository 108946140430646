/** LIBRARIES */
import React, { useState, useEffect } from 'react';


const PopUpModal = (props) => {
    const [hasEnquiryBG, setEnquiryBG] = useState(false)

    useEffect(() => {
        const { show, className } = props;

        //console.log('componentDidMount', show, document.getElementsByTagName('body')[0].classList.value.indexOf('enquiry-bg'))
        if (show && !hasEnquiryBG) {
            let pathname = window.location.pathname;
            if (pathname.indexOf('enquiry/details') >= 0 || pathname.indexOf('contacts/details') >= 0  || pathname.indexOf('service/details') >= 0 || pathname.indexOf('/dashboard') >= 0 || pathname.indexOf('/dashboard/bdc') >= 0) {
                //console.log('component - PopUpModal', pathname)
                setEnquiryBG(true);
            }
        }
        else if (!show && hasEnquiryBG) {
            //console.log('component unmount - PopUpModal', hasEnquiryBG)
            document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }

    }, [props.show])

    return (
        <>
            {props.show ? props.children : <></>}
        </>
    );
}

export default PopUpModal;