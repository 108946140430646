import React, { useEffect, useState } from 'react'
import { default as _images } from "../../images";
import { SideNav, PopUpModal, InputCheckBox } from '../../components'
import _ from 'lodash';
import moment from 'moment'
import AddUser from './addUser';
import Swal from 'sweetalert2'

const AllPosts = ({ categories, users, history, userData }) => {
  const [posts, setPosts] = useState([])
  const [loader, setLoader] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [showPreviewTemplate, setShowPreviewTemplate] = useState({
		show: false,
		title: '',
    data: null,
    image: null,
	})

  useEffect(() => {
    if (_.isEmpty(userData)) {
      return
    }

    if (!_.isEmpty(userData) && !userData.userSettingsAccess) {
      history.push('/posts')
    }
   
  }, [userData])

  const convertVM = (data, fromSearch) => {
    let objData = {...data}

    let addedDate = ''
    if (fromSearch && data.addedDate) {
      addedDate = moment.unix(data.addedDate._seconds).format('DD/MM/YYYY hh:mm A')
    } else if (!fromSearch && data.addedDate) {
       addedDate = moment(data.addedDate.toDate()).format('DD/MM/YYYY hh:mm A')
    }
    
    let lastLoginTime = ''
    if (fromSearch && data.lastLoginTime) {
      lastLoginTime = moment.unix(data.lastLoginTime._seconds).format('DD/MM/YYYY hh:mm A')
    } else if (!fromSearch && data.lastLoginTime) {
      lastLoginTime = moment(data.lastLoginTime.toDate()).format('DD/MM/YYYY hh:mm A')
    }
    objData._addedDate = addedDate ? addedDate : ''
    objData._lastLoginTime = lastLoginTime ? lastLoginTime : ''
    objData.name = data.name ? data.name : ''
    objData.email = data.email ? data.email : ''

    return objData
  }

  const handleCheckChange = async (e, docID) => {
    let isEnabled = e.target.checked

    if (localStorage.uid === docID) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Do you want to disable your account, you will be logged out?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
  
      if (!result.value) {
        return
      }
    } else {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Do you want to ${e.target.checked ? 'enable' : 'disable' } this user?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
  
      if (!result.value) {
        return
      }
    }

    // console.log('docID', docID)
    // console.log('isEnabled', isEnabled)
    const ref = window.firebase.firestore().collection('users').doc(docID)
    const resp = await ref.set({ isEnabled: isEnabled }, {merge: true})
    console.log('resp', resp)
  }

  return (
    <>
    <div id="layoutSidenav">
			<SideNav history={history} userData={userData} />
			<div id="layoutSidenav_content">
      <main>
					<div className="container-fluid px-4">
						<h1 className="mt-4 mb-4">
							Users
							<div className="float-end">
                <button type="button" className="btn btn-primary btn-sm btn-primary-fix btn-add-new-fix" onClick={() => setShowPreviewTemplate({
                  show: true,
                  title: 'Add User',
                  data: null,
                })}>
									<i className="fas fa-plus me-1"></i> Add New
								</button>
							</div>
						</h1>

						<div className="custom-card-container" style={{ minHeight: '500px'}}>
              {loader ? (
                <div className='blog-loader d-flex align-items-center justify-content-center' style={{height: '50vh'}}>
                  <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
              ) : (
							<div className="common-table">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th scope="col" className="head-light" width="22%">Name</th>
											<th scope="col" className="head-light" width="22%">Email</th>
											<th scope="col" className="head-light" width="22%">
												Added Date
											</th>
											{/* <th scope="col" className="head-light" width="10%">Tags</th> */}
											<th scope="col" className="head-light" width="22%">
												Last Login
											</th>
                      <th scope="col" className="head-light" width="8%">
												Enable/Disable
											</th>
											<th scope="col" className="head-light " width="4%">
												&nbsp;
											</th>
										</tr>
									</thead>
									<tbody>
                    {users.length > 0 ? users.map(user => convertVM(user)).map(post => (
                      <tr key={post.documentID}>
                        <td style={{fontWeight: 'bold'}}>
                          {post.name}
                        </td>
                        <td style={{fontWeight: 'bold'}}>
                          {post.email}
                        </td>
                        <td>
                          {post._addedDate ? (
                            <div className="sub-items-info">
                              <h4>{post._addedDate}</h4>
                            </div>
                          ) : '--'}
                        </td>

                        <td>
                          {post._lastLoginTime ? (
                            <div className="sub-items-info">
                              <h4>{post._lastLoginTime}</h4>
                            </div>
                          ) : '--'}
                        </td>
                        <td>
                          <span className="switch switch-sm mr-2 w-100 d-inline-block mt-2">
                              <InputCheckBox
                                  id={`is-disabled-${post.documentID}`}
                                  className="switch"
                                  name={'isEnabled'}
                                  checked={post.isEnabled}
                                  onChange={(e) => { handleCheckChange(e, post.documentID) }} />
                              <label htmlFor={`is-disabled-${post.documentID}`}></label>
                          </span>
                        </td>

                        <td className="border-left-0" style={{maxWidth: '20px'}}>
                          <div className="float-right" style={{maxWidth: '100%'}}>
                            <div className="table-edit float-left mr-2">
                              <a className="mini-button" onClick={e => setShowPreviewTemplate({
                                show: true,
                                title: 'Edit User',
                                data: post,
                              })}>
                                <i className="fas fa-pen"></i></a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )) : (<></>)}
									</tbody>
								</table>
							</div>
              )}
						</div>
					</div>
				</main>
				<footer className="py-4 mt-auto">
					<div className="container-fluid px-4">
						<div
							className="d-flex align-items-center justify-content-between small"
						>
							<div className="text-muted">
								&copy; 2021 Fusion Software Development
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
    <PopUpModal show={showPreviewTemplate.show}>
        <AddUser
          show={showPreviewTemplate.show}
          handleClose={() =>
            setShowPreviewTemplate({
              ...showPreviewTemplate,
              title: '',
              show: false,
              data: null,
            })}
          title={showPreviewTemplate.title}
          data={showPreviewTemplate.data}
          // bodyHTML={!_.isEmpty(data.template) ? data.template.bodyHTML : null}
        />
      </PopUpModal>
    </>
  )
}

export default AllPosts
