export const categoriesVM = [
  {active: true, name: 'One', value: 'one', label: 'One'},
  {active: true, name: 'Two', value: 'two', label: 'Two'},
  {active: true, name: 'Three', value: 'three', label: 'Three'},
]

export const tagsVM = [
  {active: true, name: 'One', value: 'one', label: 'One'},
  {active: true, name: 'Two', value: 'two', label: 'Two'},
  {active: true, name: 'Three', value: 'three', label: 'Three'},
]

export const statuses = [
  {active: true, name: 'Draft', value: 'Draft', label: 'Draft'},
  {active: true, name: 'Publish', value: 'Publish', label: 'Publish'},
]

export const visibilities = [
  {active: true, name: 'Public', value: 'Public', label: 'Public'},
  {active: true, name: 'Private', value: 'Private', label: 'Private'},
]