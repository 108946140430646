import React, { useEffect, useState } from 'react'
import { default as _images } from "../../images";
import { PreviewPost, SideNav, PopUpModal } from '../../components'
import _ from 'lodash';
import Swal from 'sweetalert2'
import moment from 'moment'

const AllPosts = ({ categories, tags, history, userData, users }) => {
  const [posts, setPosts] = useState([])
  const [loader, setLoader] = useState(true)
  const [hasMore, setHasMoreData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [reloadData, setReloadData] = useState(0);
  const [showPreviewTemplate, setShowPreviewTemplate] = useState({
		show: false,
		title: '',
    data: null,
    image: null,
	})
  useEffect(() => {
    const fetchData = async () => {
			let postsToAdd = []

      try {
        let ref = window.firebase.firestore().collection('posts')
        
          ref = ref
          .orderBy('addedDate', 'desc')

        const snapshots = await ref.limit(100).get()
          if (!snapshots.empty) {
            snapshots.forEach((doc) => {
              postsToAdd.push(convertVM(doc.data()))
            });
            setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
          }	
          setPosts(postsToAdd)
        setLoader(false)
      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    } 

    fetchData()
  }, [users])

  const fetchMoreData = async () => {
    if (searchText) {
      return
    }
		let postsToAdd = []
		let ref = window.firebase.firestore().collection('posts')

    // ref = ref
    // .where('status', '==', 1)
    // .where('isDeleted', '==', false)
    // .orderBy('appointmentOn', 'asc')
    // .startAfter(hasMore[0])
    // .limit(100)

		const snapshots = await ref.get()
		if (!snapshots.empty) {
			snapshots.forEach((doc) => {
				postsToAdd.push(doc.data())
			});
			setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
			setPosts(_.uniqBy([...posts, ...postsToAdd], 'documentID'))
		}	
	}

  const convertVM = (data, fromSearch) => {
    let objData = {...data}

    let addedDate = ''
    if (fromSearch && data.addedDate) {
      addedDate = moment.unix(data.addedDate._seconds).format('DD/MM/YYYY hh:mm A')
    } else if (!fromSearch && data.addedDate) {
       addedDate = moment(data.addedDate.toDate()).format('DD/MM/YYYY hh:mm A')
    }
    objData.addedDate = addedDate ? addedDate : ''

    let publishedDate = ''
    if (fromSearch && data.publishedDate) {
      publishedDate = moment.unix(data.publishedDate._seconds).format('DD/MM/YYYY hh:mm A')
    } else if (!fromSearch && data.publishedDate) {
       publishedDate = moment(data.publishedDate.toDate()).format('DD/MM/YYYY hh:mm A')
    }
    objData.publishedDate = publishedDate ? publishedDate : ''
    objData.author = users.length && users.filter(a => a.documentID === data.addedBy).length ? users.filter(a => a.documentID === data.addedBy)[0].name : ''
    objData.profileImage = users.length 
      && users.filter(a => a.documentID === data.addedBy).length 
      && users.filter(a => a.documentID === data.addedBy)[0].profileImage 
      ? users.filter(a => a.documentID === data.addedBy)[0].profileImage : ''
    objData.statusToShow = objData.status === 'Published' ? (<div className="badge badge-pill badge-green ms-1">Published</div>) : objData.status === 'Draft' ? (<div className="badge badge-pill badge-grey ms-1">Draft</div>) : ''

    return objData
  }

  return (
    <>
    <div id="layoutSidenav">
			<SideNav history={history} userData={userData} />
			<div id="layoutSidenav_content">
      <main>
					<div className="container-fluid px-4">
						<h1 className="mt-4 mb-4">
							Posts
							<div className="float-end">
                <button type="button" className="btn btn-primary btn-sm btn-primary-fix btn-add-new-fix" onClick={() => history.push(`/post/details/${window.firebase.firestore().collection('posts').doc().id}`)}>
									<i className="fas fa-plus me-1"></i> Add New
								</button>
							</div>
						</h1>

						<div className="custom-card-container" style={{ minHeight: '500px'}}>
							{/* <div className="custom-card-head">
								<div className="float-left">
									<div className="filter-search">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder="Search"
											/>
											<div className="input-group-append input-search-clear">
												<a href="#" className="input-search-clear-icon"><i className="ico icon-remove"></i></a>
												<button className="btn btn-default" type="button">
													<i className="fas fa-search"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="float-right">
									<div className="enquiry-view-more float-right mt-0 dropdown">
										<a href="#" className="common-button"
											><i className="fas fa-ellipsis-v"></i
										></a>
									</div>
								</div>
							</div> */}
              {loader ? (
                <div className='blog-loader d-flex align-items-center justify-content-center' style={{height: '50vh'}}>
                  <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
              ) : (
							<div className="common-table">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th scope="col" className="head-light" width="30%">Title</th>
											<th scope="col" className="head-light" width="15%">Author</th>
											<th scope="col" className="head-light" width="10%">
												Categories
											</th>
											{/* <th scope="col" className="head-light" width="10%">Tags</th> */}
											<th scope="col" className="head-light" width="15%">
												Created date
											</th>
											<th scope="col" className="head-light" width="15%">
												Published date
											</th>
                      <th
												scope="col"
												className="head-light border-right-0"
												width="10%"
											>
												Visibility
											</th>
											<th
												scope="col"
												className="head-light border-right-0"
												width="10%"
											>
												Status
											</th>

											<th scope="col" className="head-light border-left-0">
												&nbsp;
											</th>
										</tr>
									</thead>
									<tbody>
                    {posts.length > 0 ? posts.map(post => (
                      <tr key={post.documentID}>
                        <td style={{fontWeight: 'bold'}}>
                          {post.title}
                        </td>
                        <td>
                          <div className="profile-userthumb">
                            <div className="profile-userthumbimage">
                              {post.profileImage ? (
                                <img
                                  src={post.profileImage}
                                  alt=""
                                  className="rounded-circle"
                                />
                              ) : (
                                <img
                                  src={_images.nouser}
                                  alt=""
                                  className="rounded-circle"
                                />
                              )}
                            </div>
                            <div className="profile-userthumb-info">
                              {!_.isEmpty(post.author) ? post.author : ''}
                            </div>
                          </div>
                        </td>
                        <td>{!_.isEmpty(categories) && categories.filter(a => a.value === post.category).length ? categories.filter(a => a.value === post.category)[0].label : ''}</td>
                        {/* <td>{!_.isEmpty(tags) && tags.filter(a => a.value === post.tags[0]).length ? tags.filter(a => a.value === post.tags[0])[0].label : ''}</td> */}
                        <td>
                          {post.addedDate ? (
                            <div className="sub-items-info">
                              <h4>{post.addedDate}</h4>
                              <h5>{!_.isEmpty(post.author) ? post.author : ''}</h5>
                            </div>
                          ) : '--'}
                        </td>

                        <td>
                          {post.publishedDate ? (
                            <div className="sub-items-info">
                              <h4>{post.publishedDate}</h4>
                              <h5>{!_.isEmpty(post.author) ? post.author : ''}</h5>
                            </div>
                          ) : '--'}
                        </td>
                        <td className="border-right-0">
                          <div className="sidebar-subpanel-tag">{post.visibility ? post.visibility : 'Public'}</div>
                        </td>
                        <td className="border-right-0">
                          {post.statusToShow}
                        </td>

                        <td className="border-left-0">
                          <div className="float-right">
                            <div className="table-edit float-left mr-2">
                              <a className="mini-button" onClick={e => setShowPreviewTemplate({
                                show: true,
                                title: 'Preview Post',
                                data: post.htmlContent,
                                image: post.imageURL
                              })}>
                                <i className="fas fa-expand"></i></a>
                              <a className="mini-button ms-2" onClick={e => history.push(`/post/details/${post.documentID}`)}>
                                <i className="fas fa-pen"></i></a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )) : (<></>)}
									</tbody>
								</table>
							</div>
              )}
						</div>
					</div>
				</main>
				<footer className="py-4 mt-auto">
					<div className="container-fluid px-4">
						<div
							className="d-flex align-items-center justify-content-between small"
						>
							<div className="text-muted">
								&copy; 2021 Fusion Software Development
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
    <PopUpModal show={showPreviewTemplate.show}>
        <PreviewPost
          show={showPreviewTemplate.show}
          handleClose={() =>
            setShowPreviewTemplate({
              ...showPreviewTemplate,
              title: '',
              show: false,
              data: null,
              image: null,
            })}
          title={showPreviewTemplate.title}
          bodyHTML={showPreviewTemplate.data}
          image={showPreviewTemplate.image}
          // bodyHTML={!_.isEmpty(data.template) ? data.template.bodyHTML : null}
        />
      </PopUpModal>
    </>
  )
}

export default AllPosts
