/** LIBRARIES */
import React, { Component } from 'react'
// import Swal from 'sweetalert2';
import _ from 'lodash';
/** COMPONENTS */
// import { Routes } from '../../constants/routes';

export const RouteContext = React.createContext()

export const RouteConsumer = RouteContext.Consumer

export class RouteProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            routeLoading: false
        };

    }

    render() {

        const { children } = this.props

        return (
            <RouteContext.Provider
                value={{
                    routes: this.state.routes,
                }}
            >
                {children}
            </RouteContext.Provider>
        )
    }

}