import React from 'react';
import _ from 'lodash'

const SideNav = ({ history, userData }) => {
	return (
		<div id='layoutSidenav_nav'>
			<nav className='sb-sidenav accordion sb-sidenav-dark' id='sidenavAccordion'>
				<div className='sb-sidenav-menu'>
					<div className='nav'>
						{/* <a className='nav-link' onClick={() => history.push('/dashboard')}>
							<div className='sb-nav-link-icon'>
								<i className='fas fa-tachometer-alt'></i>
							</div>
							Dashboard
						</a> */}

						<a
							className='nav-link active'
							href='#'
							data-bs-toggle='collapse'
							data-bs-target='#collapseLayouts'
							aria-expanded='false'
							aria-controls='collapseLayouts'
						>
							<div className='sb-nav-link-icon'>
								<i className='fas fa-book-open'></i>
							</div>
							Posts
							<div className='sb-sidenav-collapse-arrow'>
								<i className='fas fa-angle-down'></i>
							</div>
						</a>
						{/* <div
								className="collapse shows"
								id="collapseLayouts"
								aria-labelledby="headingOne"
								data-bs-parent="#sidenavAccordion"
							> */}
						<nav className='sb-sidenav-menu-nested nav '>
							<a className={`nav-link cursor-pointer ${window.location.pathname === '/posts' ? 'active' : ''}`} onClick={() => history.push('/posts')}>
								All Posts
							</a>
							{/* add active below class */}
							<a className={`nav-link cursor-pointer ${window.location.pathname.includes('/post/details') ? 'active' : ''}`} onClick={() => history.push(`/post/details/${window.firebase.firestore().collection('posts').doc().id}`)}>
								Add New
							</a>
							<a className={`nav-link cursor-pointer ${window.location.pathname.includes('/categories') ? 'active' : ''}`} onClick={() => history.push('/categories')}>
								Categories
							</a>
							{/* <a className={`'nav-link cursor-pointer`} onClick={() => history.push('/tags')}>
								Tags
							</a> */}
						</nav>
						{/* </div> */}

						{/* <a className='nav-link' onClick={() => history.push('/tags')}>
							<div className='sb-nav-link-icon'>
								<i className='fas fa-photo-video'></i>
							</div>
							Media
						</a>

						<a className='nav-link' onClick={() => history.push('/tags')}>
							<div className='sb-nav-link-icon'>
								<i className='fas fa-comments'></i>
							</div>
							Comments
						</a> */}
						{!_.isEmpty(userData) && userData.userSettingsAccess && (
							<a className='nav-link' onClick={() => history.push('/users')}>
								<div className='sb-nav-link-icon'>
									<i className='fas fa-user-cog'></i>
								</div>
								Users
							</a>
						)}
						{/* <a className='nav-link' onClick={() => history.push('/settings')}>
							<div className='sb-nav-link-icon'>
								<i className='fas fa-cog'></i>
							</div>
							Settings
						</a> */}
					</div>
				</div>
			</nav>
		</div>
	);
};

export default SideNav;
