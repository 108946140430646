export const createAllSpaceKeywords = (name) => {
  const arr = [];
  arr.push(name.toLowerCase().trim());
  const words = name.split(' ');
  for (let i = 0; i < words.length; i++) {

      // create an empty string
      let comb = "";
      // loop for substring
      for (let j = i; j < words.length; j++) {
          comb += ' ' + words[j];
          if (comb && comb.trim() !== '') arr.push(comb.toLowerCase().trim());
      }
  }
  const uniq = [...new Set(arr)];
  return uniq;
}