import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { InputText } from '../../components';
import _ from 'lodash'
import moment from 'moment'

const AddCategory = (props) => {
    const [errorFields, setErrorFields] = useState({})
    const [data, setData] = useState(props.data ? props.data : {
      name: '',
      active:true,
      documentID: '',
      label: '',
      value: '',
    })

    const handleOnChange = e => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value,
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    const onSave = async e => {
        e.preventDefault()
        let isValid = true;
        let errors = { ...errorFields }
        if (data.name.trim() === '') {
            isValid = false;
            errors.name = 'input_error'
        }
        setErrorFields(errors);

        if (!isValid) {
          return
			  } else {
          if (props.title === 'Edit Category') {
              const objData = {
                ...data,
                label: data.name.trim() ? data.name.trim() : '',
                name: data.name.trim() ? data.name.trim() : '',
              }

              const ref = window.firebase.firestore().collection('categories').doc(objData.documentID)
              ref.set(objData, {merge: true})
              console.log('update objData', objData)
              props.handleClose(objData)
          } else {
              const objData = {
                ...data,
                active:true,
                documentID: window.firebase.firestore().collection('categories').doc().id,
                label: data.name.trim() ? data.name.trim() : '',
                name: data.name.trim() ? data.name.trim() : '',
                value: moment()._d.getTime().toString(),
                addedDate: window.firebase.firestore.Timestamp.now()
              }

              const ref = window.firebase.firestore().collection('categories').doc(objData.documentID)
              ref.set(objData, {merge: true})
              console.log('add objData', objData)
              props.handleClose(objData)
          }
        }
    }

    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-save-filter">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > {props.title}</h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["name"]}`}
                                    name="name"
                                    onChange={handleOnChange}
                                    value={data.name}
                                    placeholder='name'
                                />
                            </div>
                          </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" style={{width: '60px'}} className={`btn btn-add-new-fix btn-primary btn-primary-fix float-right`} onClick={onSave}>
              {props.title === 'Edit Category' ? 'Save' : 'Add'}
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                Cancel
            </button>

        </Modal.Footer>
    </Modal>)
}

export default AddCategory;