import React, { useEffect, useState } from 'react'
import { default as _images } from "../../images";
import { PreviewPost, SideNav, PopUpModal } from '../../components'
import _ from 'lodash';
import AddTag from './add'
import Swal from 'sweetalert2'
import moment from 'moment'

const Tags = ({ categories, tags, history, userData }) => {
  const [posts, setPosts] = useState([])
  const [loader, setLoader] = useState(false)
  const [hasMore, setHasMoreData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [reloadData, setReloadData] = useState(0);
  const [showPreviewTemplate, setShowPreviewTemplate] = useState({
		show: false,
		title: '',
    data: null,
	})
  // useEffect(() => {
  //   const fetchData = async () => {
	// 		let postsToAdd = []

  //     try {
  //       let ref = window.firebase.firestore().collection('tags')
  //       const snapshots = await ref.limit(100).get()
  //         if (!snapshots.empty) {
  //           snapshots.forEach((doc) => {
  //             postsToAdd.push(convertVM(doc.data()))
  //           });
  //           setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
  //         }	
  //         setPosts(postsToAdd)
  //       setLoader(false)
  //     } catch (error) {
  //       console.log(error);
  //       setLoader(false)
  //     }
  //   } 

  //   fetchData()
  // }, [])

  // const fetchMoreData = async () => {
  //   if (searchText) {
  //     return
  //   }
	// 	let postsToAdd = []
	// 	let ref = window.firebase.firestore().collection('tags')

  //   // ref = ref
  //   // .where('status', '==', 1)
  //   // .where('isDeleted', '==', false)
  //   // .orderBy('appointmentOn', 'asc')
  //   // .startAfter(hasMore[0])
  //   // .limit(100)

	// 	const snapshots = await ref.get()
	// 	if (!snapshots.empty) {
	// 		snapshots.forEach((doc) => {
	// 			postsToAdd.push(doc.data())
	// 		});
	// 		setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
	// 		setPosts(_.uniqBy([...posts, ...postsToAdd], 'documentID'))
	// 	}	
	// }

  // const convertVM = (data, fromSearch) => {
  //   let objData = {...data}

  //   return objData
  // }

  return (
    <>
    <div id="layoutSidenav">
			<SideNav history={history} userData={userData} />
			<div id="layoutSidenav_content">
      <main>
					<div className="container-fluid px-4">
						<h1 className="mt-4 mb-4">
							Tags
							<div className="float-end">
								<button type="button" className="btn btn-secondary btn-sm" onClick={() => setShowPreviewTemplate({
                                              show: true,
                                              title: 'Add Tag',
                                              data: null,
                                            })}>
									<i className="fas fa-plus me-1"></i> Add New
								</button>
							</div>
						</h1>

						<div className="custom-card-container" style={{ minHeight: '500px'}}>
							{/* <div className="custom-card-head">
								<div className="float-left">
									<div className="filter-search">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder="Search"
											/>
											<div className="input-group-append input-search-clear">
												<button className="btn btn-default" type="button">
													<i className="fas fa-search"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								
							</div> */}

							<div className="common-table">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th scope="col" className="head-light" width="30%">Title</th>
											

											<th scope="col" className="head-light border-left-0">
												&nbsp;
											</th>
										</tr>
									</thead>
									<tbody>
                    {tags.length > 0 ? tags.map(post => (
                      <tr>
                        <td>
                          {post.label}
                        </td>
                        

                        <td className="border-left-0">
                          <div className="float-right">
                            <div className="table-edit float-left mr-2">
                              <a className="mini-button float-right" onClick={e => setShowPreviewTemplate({
                                      ...showPreviewTemplate,
                                      title: 'Edit Tag',
                                      show: true,
                                      data: post,
                                    })}>
                                <i className="fas fa-pen"></i></a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )) : (<></>)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</main>
				<footer className="py-4 mt-auto">
					<div className="container-fluid px-4">
						<div
							className="d-flex align-items-center justify-content-between small"
						>
							<div className="text-muted">
								&copy; 2021 Fusion Software Development
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
    <PopUpModal show={showPreviewTemplate.show}>
        <AddTag
          show={showPreviewTemplate.show}
          handleClose={(objData) => {
            setShowPreviewTemplate({
              ...showPreviewTemplate,
              title: '',
              show: false,
              data: null,
            })
          }}
          title={showPreviewTemplate.title}
          data={showPreviewTemplate.data}
        />
      </PopUpModal>
    </>
  )
}

export default Tags
