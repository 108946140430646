/** LIBRARIES */
import React, { Component, Suspense, } from 'react';
/** PROVIDERS */
import LayoutProvider, {LayoutConsumer } from './provider';
import { RouteProvider } from './RouteProvider';
/** COMPONENTS */
import Navbar from './navbar';
import MainContainer from './container';

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
          sidePanel: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSidePanel = (val) => {
      this.setState({
        sidePanel: val ? val : false
      })
    }

    render() {
        return (
          <LayoutProvider history={this.props.history}>
            <LayoutConsumer>
                {({ categories, tags, userData, users }) => (
                    <RouteProvider history={this.props.history} categories={categories} tags={tags} userData={userData} users={users} >
                        <Suspense fallback={<></>}>
                            {
                              <>
                                  <Navbar history={this.props.history} handleSidePanel={this.handleSidePanel} categories={categories} tags={tags} userData={userData} users={users} />
                                  <MainContainer history={this.props.history} handleSidePanel={this.handleSidePanel} sidePanelShow={this.state.sidePanel} categories={categories} tags={tags} userData={userData} users={users} />
                              </>
                            }
                        </Suspense>
                    </RouteProvider>
                )}
            </LayoutConsumer>
        </LayoutProvider>
        )
    }
}

export default DefaultLayout;