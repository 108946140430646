import React, {useEffect} from 'react'
import { default as _images } from "../../images";
import Swal from 'sweetalert2'
import _ from 'lodash'

const Navbar = ({ userData, history }) => {
	const [show, setShow] = React.useState(false)

	const handleLogout = (e) => {
		e.preventDefault()
		Swal.fire({
				title: 'Are you sure?',
				text: 'Do you want to logout?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
		}).then((result) => {
				if (result.value) {
						window.firebase.auth().signOut()
							.then(() => {
									localStorage.clear();
									history.push('/login')
							})
							.catch(error => {
									console.error(error);
							});
				}
		})
};

  useEffect(() => {
    console.log('class add')
		document.getElementsByTagName('body')[0].classList.add('sb-nav-fixed');
		return () => {
      console.log('class remove')
      document.getElementsByTagName('body')[0].classList.remove('sb-nav-fixed');
		};
	}, []);

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark navbar-fix">
			{/* <!-- Navbar Brand--> */}
			<a className="navbar-brand ps-3 text-left" onClick={() => history.push('/posts')}
				><img src={_images.logo} height="38" alt=""
				
			/></a>
			{/* <!-- Sidebar Toggle--> */}
			{/* <button
				className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
				id="sidebarToggle"
				href="#!"
			>
				<i className="fas fa-bars"></i>
			</button> */}
			{/* <!-- Navbar Search--> */}
			<div
				className="
					d-none d-md-inline-block
					form-inline
					ms-auto
					me-0 me-md-3
					my-2 my-md-0
				"
			></div>
			{/* <!-- Navbar--> */}
			<ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
				<li className="nav-item dropdown">
					<a
						className="nav-link dropdown-toggle user-profile pt-0"
						href="#"
						id="navbarDropdown"
						role="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						onClick={() => setShow(!show)}
					>
						{!_.isEmpty(userData) && userData.profileImage ? (
							<img src={!_.isEmpty(userData) && userData.profileImage ? userData.profileImage : _images.nouser} alt="" />
						): (
							<img src={_images.nouser} alt="" />
						)}
						<div className="user-name mt-2 me-1">
							<div>{!_.isEmpty(userData) ? userData.name : ''}</div>
						</div>
					</a>

					<ul
						className={`dropdown-menu dropdown-menu-end ${show ? 'show' : ''}`}
						aria-labelledby="navbarDropdown"
					>
						{/* <li><a className="dropdown-item" href="#!">Settings</a></li>
						<li><a className="dropdown-item" href="#!">Activity Log</a></li>
						<li><hr className="dropdown-divider" /></li> */}
						<li><a className="dropdown-item" onClick={handleLogout}>Logout</a></li>
					</ul>
				</li>
			</ul>
		</nav>
  )
}

export default Navbar
